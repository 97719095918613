import { formatDistanceStrict, isValid } from 'date-fns'
import { enUS, es, frCH, it, sk } from 'date-fns/locale'

const languages = {
    sk: sk,
    en: enUS,
    it,
    es,
    fr: frCH,
}

/**
 * Course expiration helper.
 *
 * @param {Date} start
 * @param {Date} end
 * @param {string} lang
 * @param {boolean} admin
 *
 * @return {string}
 */
export const courseExpiration = (start, end, lang = 'sk', admin = false) => (isValid(start) && isValid(end) && formatDistanceStrict(end, start, {
    addSuffix: true,
    locale: admin ? sk : languages[lang],
})) || ''
